import React from 'react'
import MainInner from '../components/MainInner'
import Seo from '../components/Seo'

export default function NotFoundPage() {
  return (
    <MainInner>
      <Seo title="404: Not found" />
      <h1>Not Found</h1>

      <p>Sorry, you've hit a URL that doesn't exist!</p>
    </MainInner>
  )
}
